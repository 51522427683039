<template>
  <div class="edit-skill">
    <my-dialog :show="show" :fullscreen="true" @close="close">
      <div class="card" v-if="originalRecord && record">
        <div class="card__header">
          <div class="card__title">{{__('component.player_progression.edit_skill.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs4">
              <h2>{{__('common.edit_properties')}}</h2>
              <div class="layout layout--wrap">
                <div class="flex xs12">
                  <text-field
                      v-model="record.name"
                      :label="__('entity.common.name')"
                      :error-messages="errors.name"
                      maxlength="150"
                      :prepend-icon="isChanged('name') ? 'pencil' : null"
                      @input="registerChange('name')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.icon"
                      :label="__('entity.common.icon')"
                      :error-messages="errors.icon"
                      :prepend-icon="isChanged('icon') ? 'pencil' : null"
                      @input="registerChange('icon')"
                  />
                </div>
                <div class="flex xs12">
                  <label>{{ __('entity.common.skill_group') }}</label>
                  <select-reference
                      v-model="record.skillGroupId"
                      :endpoint="'/ingame/player-progression/skillgroup'"
                      :nothing-selected-message="__('common.no_skill_group_selected')"
                      :prepend-icon="isChanged('skillGroupId') ? 'pencil' : ''"
                      @input="registerChange('skillGroupId')"
                  />
                </div>
                <div class="flex xs12 mt">
                  <text-field
                      v-model="record.sorting"
                      :label="__('entity.common.sorting')"
                      :error-messages="errors.sorting"
                      :type="'number'" min="0"
                      :prepend-icon="isChanged('sorting') ? 'pencil' : null"
                      @input="registerChange('sorting')"
                  />
                </div>
                <div class="flex xs12">
                  <text-area
                      v-model="record.description"
                      :label="__('entity.common.description')"
                      :error-messages="errors.description"
                      :prepend-icon="isChanged('description') ? 'pencil' : null"
                      @input="registerChange('description')"
                  />
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0"
                       @click="update" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
                </div>
              </div>
            </div>
            <div class="flex xs8">
              <edit-skill-levels
                  ref="editSkillLevels"
                  :skill-id="record.id"
                  @updated="$emit('updated')"
              ></edit-skill-levels>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import OriginalSkill from '../../components/PlayerProgression/EditSkill/OriginalSkill';
  import EditSkillLevels from "./EditSkill/EditSkillLevels";
  import { editFormMixin } from "../../mixins/editForm";

  export default {
    components: {
      EditSkillLevels,
      OriginalSkill
    },
    mixins: [notificationsMixin, localizationMixin, editFormMixin],
    props: ['preloadedSkillGroups'],
    data: () => ({
      endpoint: '/ingame/player-progression/skill',
      emptyRecord: { id: null, name: '', skillGroupId: null, sorting: 0, icon: null, createdAt: '---', updatedAt: '---' },
    }),
  }
</script>