<template>
  <div class="edit-skill-group">
    <my-dialog :show="show" @close="close" v-if="originalRecord && record" max-width="400px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.player_progression.edit_skill_group.title')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <table class="object-table">
                <tr>
                  <td>{{__('entity.common.icon')}}:</td>
                  <td><img :src="originalRecord.icon" alt="no icon"/></td>
                </tr>
                <tr>
                  <td>{{__('entity.common.id')}}:</td>
                  <td>{{ originalRecord.id}}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.name')}}:</td>
                  <td>{{ originalRecord.name }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.created_at')}}:</td>
                  <td>{{ originalRecord.createdAt }}</td>
                </tr>
                <tr>
                  <td>{{__('entity.common.updated_at')}}:</td>
                  <td>{{ originalRecord.updatedAt }}</td>
                </tr>
              </table>
            </div>
            <div class="flex xs12 mt">
              <text-field
                  v-model="record.name"
                  :label="__('entity.common.name')"
                  :error-messages="errors.name"
                  maxlength="150"
                  :prepend-icon="isChanged('name') ? 'pencil' : null"
                  @input="registerChange('name')"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="record.icon"
                  :label="__('entity.common.icon')"
                  :error-messages="errors.icon"
                  maxlength="150"
                  :prepend-icon="isChanged('icon') ? 'pencil' : null"
                  @input="registerChange('icon')"
              />
            </div>
            <div class="flex xs12">
              <text-field
                  v-model="record.sorting"
                  :label="__('entity.common.sorting')"
                  :error-messages="errors.sorting"
                  :type="'number'" min="0"
                  :prepend-icon="isChanged('sorting') ? 'pencil' : null"
                  @input="registerChange('sorting')"
              />
            </div>
            <div class="flex xs12">
              <label>{{ __('parentSkillGroup') }}</label>
              <select-reference
                  v-model="record.parentId"
                  :endpoint="'/ingame/player-progression/skillgroup'"
                  :nothing-selected-message="__('noParentSkillGroupSelected')"
                  :prepend-icon="isChanged('parentId') ? 'pencil' : ''"
                  @input="registerChange('parentId')"
              />
            </div>
            <div class="flex xs6 mt">
              <div class="btn btn--success" :loading="loading" :disabled="changedProperties.length === 0" @click="update" v-slashes>{{ __('common.save_changes') }}</div>
            </div>
            <div class="flex xs6 mt">
              <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { notificationsMixin } from '../../mixins/notifications';
  import { localizationMixin } from '../../mixins/localization';
  import { editFormMixin } from "../../mixins/editForm";

  export default {
    mixins: [notificationsMixin, localizationMixin, editFormMixin],
    data: () => ({
      endpoint: '/ingame/player-progression/skillgroup',
      emptyRecord: { id: null, name: '', icon: null, tier: 1, sorting: 0, parentId: null, createdAt: '---', updatedAt: '---' },
    }),
  }
</script>