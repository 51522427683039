<template>
  <div class="skill-level-browser">
    <my-dialog :show="show" @close="close" max-width="600px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('component.player_progression.edit_skill.edit_required_skill_level.title')}}
          </div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <progress-linear v-if="loading" :indeterminate="true"/>
        <div class="card__content">
          <text-field
              class="control-bar__search"
              v-model="searchTerm"
              :label="__('common.search_term')"
              maxlength="50"
              :hide-details="true"
              :clearable="true"
              @keyup:enter="refresh"
              @click:clear="refresh"
          />
          <div class="selection-matrix" v-if="skills.length > 0">
            <div class="selection-matrix__item" v-for="skill in skills">
              <div class="selection-matrix__item-title">
                {{ skill.name }}
              </div>
              <div class="selection-matrix__item-value" v-for="skillLevel in skill.skillLevels"
                   @click="emitSelection(skillLevel, skill)" v-slashes>
                {{ skillLevel.stage }}
              </div>
            </div>
          </div>
          <p class="mt" v-else>{{__('component.player_progression.edit_skill.edit_required_skill_level.search_first')}}</p>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';

  export default {
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      show: false,
      loading: false,
      skills: [],
      searchTerm: ''
    }),
    methods: {
      async refresh() {
        if (this.searchTerm.length < 3) {
          await Swal.fire({
            type: 'warning',
            text: this.__('common.min_three_chars')
          });
          return;
        }
        this.loading = true;
        this.skills = [];
        await apiSecured.get('/ingame/player-progression/skill', {
          params: {
            searchQuery: this.searchTerm,
            pageSize: 999,
            orderBy: 'name',
          }
        }).then(async (res) => {
          this.skills = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.skill_loaded'));
          await this.enrichRecords();
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async enrichRecords() {
        const skillIds = this.skills.map(skill => skill.id);
        this.loading = true;
        await apiSecured.get(`/ingame/player-progression/skilllevel/by-skill/(${skillIds.join(',')})`,{
          params: {
            orderBy: 'stage'
          }
        }).then((res) => {
          this.skills = this.skills.map(skill => {
            skill.skillLevels = res.data.filter(skillLevel => skillLevel.skillId === skill.id);
            return skill;
          });
          this.loading = false;
          this.showInfoNotification(this.__('common.skill_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      showDialog() {
        this.show = true;
      },
      close() {
        this.show = false;
      },
      emitSelection(skillLevel, skill) {
        skillLevel.skill = skill;
        this.$emit('skillLevelSelected', {skillLevel, skill});
        this.show = false;
      }
    }
  }
</script>