<template>
  <div class="view">
    <app-header :title="__('view.ingame.skill_tree.title')"/>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="control-bar" :class="{'control-bar--disabled': loading}">
      <div class="control-bar__info" v-if="player">
        <div class="control-bar__info-title">{{__('view.ingame.skill_tree.invested_skill_points')}}:</div>
        <div class="control-bar__info-value">{{ investedSkillPoints.toLocaleString() }}</div>
      </div>
      <div class="control-bar__info" v-if="faction">
        <div class="control-bar__info-title">{{__('members')}}:</div>
        <div class="control-bar__info-value">{{ skillTree.countPlayers }}</div>
      </div>
      <div class="control-bar__buttons">
        <div class="control-bar__simple-select" v-if="players.length > 0">
          <div class="control-bar__simple-select-item"
               :class="{'control-bar__simple-select-item--selected': faction && faction.id === availableFaction.id}"
               v-for="availableFaction in factions"
               @click="selectFaction(availableFaction)"
          >
            {{availableFaction.name}}
          </div>
          <div class="control-bar__simple-select-item"
               :class="{'control-bar__simple-select-item--selected': player && player.id === availablePlayer.id}"
               v-for="availablePlayer in players"
               @click="selectPlayer(availablePlayer)"
          >
            {{availablePlayer.name}}
          </div>
        </div>
        <div class="control-bar__featured" v-else>
          <div class="control-bar__featured-hint text--error">{{__('view.ingame.skill_tree.no_players_assigned')}}</div>
        </div>
        <div class="control-bar__button" :title="__('addPlayer')" @click="$refs.getPlayerRegistrationToken.showDialog()">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/player-add-mc.svg"
               :alt="'addPlayer'"/>
        </div>
        <div class="control-bar__button" :title="__('manageOrganizations')" @click="$refs.organizationLegateView.showDialog()">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/organizations-mc.svg"
               :alt="'manageOrganizations'"/>
        </div>
        <div class="control-bar__button ml" :title="__('common.refresh')" @click="refresh">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/refresh.svg"
               alt="refresh"/>
        </div>
        <div class="control-bar__button" :title="__('view.ingame.skill_tree.add_skill_group')" v-if="editMode"
             @click="addSkillGroup(null)">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/add.svg" alt="add"/>
        </div>
        <div class="control-bar__button"
             v-if="hasOneRoles(['Ingame Items Editor'])"
             :class="{'control-bar__button--active': editMode}"
             :title="__('view.ingame.skill_tree.toggle_edit_mode')"
             @click="editMode = !editMode">
          <img class="control-bar__button-icon" src="https://cdn.hyperion-corporation.de/ui/svgs/pencil.svg"
               alt="edit"/>
        </div>
      </div>
    </div>

    <div class="skill-tree" id="skill_tree" v-if="skillTree">

      <div class="skill-tree__sticky-nav">
        <div class="skill-tree__sticky-nav-item"
             v-for="mainSkillGroup in skillTree.mainSkillGroups"
             v-scroll-to="{container: '#skill_tree', el: `#m_${mainSkillGroup.id}`, offset: -90}"
        >
          <div class="skill-tree__sticky-nav-item-icon"><img :src="mainSkillGroup.icon ? mainSkillGroup.icon : defaultIcon" :alt="mainSkillGroup.name"/></div>
          <div class="skill-tree__sticky-nav-item-name">{{mainSkillGroup.name}}</div>
        </div>
      </div>

      <div class="skill-tree__main-skill-group" :id="`m_${mainSkillGroup.id}`" v-for="mainSkillGroup in skillTree.mainSkillGroups">

        <div class="skill-tree__main-skill-group-header">
          <div class="skill-tree__main-skill-group-icon"><img :src="mainSkillGroup.icon ? mainSkillGroup.icon : defaultIcon" :alt="mainSkillGroup.name"/></div>
          <div class="skill-tree__main-skill-group-name">{{mainSkillGroup.name}}</div>
          <div class="skill-tree__main-skill-group-action" :title="__('view.ingame.skill_tree.add_skill_group')" v-if="editMode"
               @click="addSkillGroup(mainSkillGroup)">
            <icon icon="add"/>
          </div>
          <div class="skill-tree__main-skill-group-action" v-if="editMode" @click="editSkillGroup(mainSkillGroup)"
               :title="__('common.edit')">
            <icon icon="pencil"/>
          </div>
          <div class="skill-tree__main-skill-group-action" v-if="editMode" @click="deleteRecord(mainSkillGroup,'skillgroup')"
               :title="__('common.delete')">
            <icon icon="bin"/>
          </div>
        </div>

        <div class="skill-tree__skill-groups-wrapper">
          <div class="skill-tree__skill-group" v-for="skillGroup in mainSkillGroup.skillGroups">
            <div class="skill-tree__skill-group-header">
              <div class="skill-tree__skill-group-icon" v-if="skillGroup.icon"><img :src="skillGroup.icon" :alt="skillGroup.name"/></div>
              <div class="skill-tree__skill-group-name">{{skillGroup.name}}</div>
              <div class="skill-tree__skill-group-action" v-if="editMode" @click="addSkill(skillGroup)"
                   :title="__('view.ingame.skill_tree.add_skill')">
                <icon icon="add"/>
              </div>
              <div class="skill-tree__skill-group-action" v-if="editMode" @click="editSkillGroup(skillGroup)"
                   :title="__('common.edit')">
                <icon icon="pencil"/>
              </div>
              <div class="skill-tree__skill-group-action" v-if="editMode" @click="deleteRecord(skillGroup,'skillgroup')"
                   :title="__('common.delete')">
                <icon icon="bin"/>
              </div>
            </div>

            <div class="skill-tree__skills-wrapper">
              <div class="skill-tree__skill" v-for="skill in skillGroup.skills">
                <div class="skill-tree__skill-header">
                  <div class="skill-tree__skill-icon"><img :src="skill.icon ? skill.icon : defaultIcon" :alt="skillGroup.name"/></div>
                  <div class="skill-tree__skill-name">{{skill.name}}</div>
                  <div class="skill-tree__skill-action" v-if="editMode" @click="editSkill(skill)"
                       :title="__('common.edit')">
                    <icon icon="pencil"/>
                  </div>
                  <div class="skill-tree__skill-action" v-if="editMode" @click="deleteRecord(skill,'skill')"
                       :title="__('common.delete')">
                    <icon icon="bin"/>
                  </div>
                </div>
                <div class="skill-tree__skill-description">{{skill.description}}</div>
                <div class="skill-tree__skill-levels-wrapper">
                  <div class="skill-tree__skill-level"
                       v-for="skillLevel in skill.skillLevels"
                  >
                    <div class="skill-tree__skill-level-action"
                         v-if="faction"
                         :class="getSkillLevelActionClasses(skillLevel)"
                         :title="__('clickToSeeInfoAndPlayers')"
                         @click="selectSkill(skill, skillLevel.stage)"
                         v-slashes
                    >
                      <span class="skill-tree__skill-level-action-count"
                            :style="{background: `linear-gradient(90deg, #07963a ${skillLevel.percentUnlocked}%, transparent ${skillLevel.percentUnlocked}%)`}"
                      >
                        {{skillLevel.players.length}}
                      </span>
                    </div>

                    <div class="skill-tree__skill-level-action"
                         v-else
                         :class="getSkillLevelActionClasses(skillLevel)"
                         :title="getSkillLevelActionHint(skillLevel)"
                         @click="handleSkillLevelClick(skillLevel)"
                         v-slashes
                    >
                      <span class="skill-tree__skill-level-action-points">{{skillLevel.requiredTalentPoints.toLocaleString()}}</span>
                      <icon class="skill-tree__skill-level-action-icon-disabled" icon="lock"
                            v-if="!skillLevel.unlockable"/>
                      <icon class="skill-tree__skill-level-action-icon-unlocked" icon="check"
                            v-if="skillLevel.players.length > 0"/>
                      <icon class="skill-tree__skill-level-action-icon-delete" icon="bin"
                            v-if="skillLevel.players.length > 0"/>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>

      </div>
    </div>

    <edit-skill-group
        ref="editSkillGroup"
        @updated="refresh"
        @created="editSkillGroup"
    />
    <edit-skill
        ref="editSkill"
        @updated="refresh"
        @created="editSkill"
    />
    <get-player-registration-token ref="getPlayerRegistrationToken"/>
    <organization-legate-view ref="organizationLegateView"/>
    <skill-details ref="skillDetails" />
  </div>
</template>

<script>
  import Swal from 'sweetalert2';
  import apiSecured from '../../api/secured';
  import AppHeader from '../../components/AppHeader';
  import { notificationsMixin } from '../../mixins/notifications';
  import { userMixin } from "../../mixins/user";
  import { localizationMixin } from '../../mixins/localization';
  import EditSkillGroup from "../../components/PlayerProgression/EditSkillGroup";
  import EditSkill from "../../components/PlayerProgression/EditSkill";
  import { pageTitleMixin } from "../../mixins/pageTitle";
  import GetPlayerRegistrationToken from "../../components/Ingame/GetPlayerRegistrationToken";
  import OrganizationLegateView from "../../components/Ingame/OrganizationLegateView";
  import SkillDetails from "../../components/PlayerProgression/SkillDetails";

  export default {
    mixins: [notificationsMixin, userMixin, localizationMixin, pageTitleMixin],
    components: {
      SkillDetails,
      OrganizationLegateView,
      GetPlayerRegistrationToken,
      EditSkill,
      EditSkillGroup,
      AppHeader
    },
    data: () => ({
      defaultIcon: 'https://cdn.hyperion-corporation.de/ingame/iconsLib/talentslib/missing.svg',
      loading: false,
      skillTree: null,
      players: [],
      player: null,
      editMode: false,
      investedSkillPoints: 0,
      factions: [],
      faction: null,
    }),
    methods: {
      selectSkill(skill, stage) {
        this.$refs.skillDetails.showAside(skill, stage);
      },
      selectPlayer(player) {
        this.faction = null;
        this.player = player;
        this.refreshSkillTree();
      },
      selectFaction(faction) {
        this.player = null;
        this.faction = faction;
        this.refreshSkillTree();
      },
      calculateInvestedSkillPoints() {
        this.investedSkillPoints = 0;
        if (this.skillTree) {
          this.skillTree.mainSkillGroups.forEach(mainSkillGroup => {
            mainSkillGroup.skillGroups.forEach(skillGroup => {
              skillGroup.skills.forEach(skill => {
                skill.skillLevels.forEach(skillLevel => {
                  if (skillLevel.players.length > 0) {
                    this.investedSkillPoints += skillLevel.requiredTalentPoints;
                  }
                });
              });
            });
          });
        }
      },
      handleSkillLevelClick(skillLevel) {
        if (!this.loading && this.player !== null) {
          if (skillLevel.players.length > 0) {
            this.lockSkillLevel(skillLevel);
          } else {
            this.unlockSkillLevel(skillLevel);
          }
        }
      },
      getSkillLevelActionClasses(skillLevel) {
        const disabled = this.faction === null && (this.players.length === 0 || !skillLevel.unlockable);
        const unlocked = this.faction === null && skillLevel.players.length > 0;
        return {
          'skill-tree__skill-level-action--disabled': disabled,
          'skill-tree__skill-level-action--unlocked': unlocked,
        }
      },
      getSkillLevelActionHint(skillLevel) {
        if (!skillLevel.unlockable)
          return this.__('view.ingame.skill_tree.click_for_required');
        else if (skillLevel.players.length > 0)
          return this.__('view.ingame.skill_tree.click_to_lock');
        else if (this.players.length === 0)
          return this.__('view.ingame.skill_tree.no_players_assigned');

        return this.__('view.ingame.skill_tree.click_to_unlock');
      },
      async addSkillGroup(parentSkillGroup = null) {
        await this.refreshSkillTree();
        let prefill = null;
        if (parentSkillGroup) {
          prefill = {
            parentId: parentSkillGroup.id,
            sorting: parentSkillGroup.skillGroups.length > 0 ? parentSkillGroup.skillGroups.length : 0,
          }
        }
        this.$refs.editSkillGroup.showCreate(prefill);
      },
      async editSkillGroup(skillGroup) {
        await this.refreshSkillTree();
        this.$refs.editSkillGroup.showEdit(skillGroup);
      },
      async addSkill(skillGroup) {
        await this.refreshSkillTree();
        const prefill = {
          skillGroupId: skillGroup.id,
          sorting: skillGroup.skills.length > 0 ? skillGroup.skills.length : 0,
        }
        this.$refs.editSkill.showCreate(prefill);
      },
      async editSkill(skill) {
        await this.refreshSkillTree();
        this.$refs.editSkill.showEdit(skill);
      },
      async refresh() {
        await this.refreshPlayers();
        await this.refreshFactions();
        await this.refreshSkillTree();
      },
      async refreshSkillTree() {
        this.loading = true;
        const params = {
          playerId: this.player ? this.player.id : null,
          factionId: this.faction ?  this.faction.id : null,
        }
        await apiSecured.get('/ingame/player-progression/skilltree',{params}).then((res) => {
          this.skillTree = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async refreshPlayers() {
        this.loading = true;
        await apiSecured.get('/ingame/civilization/player/by-current-user').then((res) => {
          this.players = res.data;
          this.loading = false;
          if (this.players.length > 0 && (this.player === null || !this.players.some(player => player.id === this.player.id))) {
            this.selectPlayer(this.players[0]);
          }
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async refreshFactions() {
        if (!this.player)
          return;

        this.loading = true;
        this.factions = [];
        const orgIds = [];
        this.players.forEach(player => {
          player.organizationMemberships.forEach(membership => {
            orgIds.push(membership.organizationId);
          });
        })
        if (orgIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${orgIds.join(',')})`).then((res) => {
            this.factions = res.data.filter(rec => rec.isFaction);
            this.loading = false;
            this.showInfoNotification('Factions loaded');
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
          });
        }
      },
      unlockSkillLevel(skillLevel) {
        this.loading = true;
        let endpoint = '/ingame/player-progression/skilllevelunlock';
        const payload = {
          skillLevelId: skillLevel.id,
          playerId: this.player.id
        };
        apiSecured.post(endpoint, payload).then((res) => {
          this.loading = false;
          this.showSuccessNotification(this.__('view.ingame.skill_tree.unlocked'));
          this.refreshSkillTree();
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      lockSkillLevel(skillLevel) {
        this.loading = true;
        let endpoint = '/ingame/player-progression/skilllevelunlock/delete';
        const payload = {
          skillLevelId: skillLevel.id,
          playerId: this.player.id
        };
        apiSecured.post(endpoint, payload).then((res) => {
          this.loading = false;
          this.showSuccessNotification(this.__('view.ingame.skill_tree.locked'));
          this.refreshSkillTree();
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async deleteRecord(record, endpoint) {
        await Swal.fire({
          title: this.__('common.are_you_sure'),
          text: this.__('common.do_you_want_to_delete'),
          type: 'warning',
          showCancelButton: true,
          confirmButtonText: this.__('common.yes_delete_it')
        }).then(async (result) => {
          let endpointUri = `/ingame/player-progression/${endpoint}/${record.id}`;
          if (result.value) {
            this.loading = true;
            await apiSecured.delete(endpointUri).then((res) => {
              this.loading = false;
              this.showSuccessNotification(res.data.message);
              this.refreshSkillTree();
            }).catch((error) => {
              this.loading = false;
              this.showErrorNotifications(error);
              this.refreshSkillTree();
            });
          }
        });
      },
    },
    created() {
      this.setPageTitle(this.__('common.skill_tree'));
      this.refresh();
    }
  }
</script>
