<template>
  <div class="original-skill">
    <h2>{{__('component.player_progression.edit_skill.original_skill.title')}}</h2>
    <table class="object-table" v-if="originalSkill.id != null">
      <tr>
        <td>{{__('entity.common.id')}}:</td>
        <td>{{ originalSkill.id}}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.name')}}:</td>
        <td>{{ originalSkill.name }}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.skill_group')}}:</td>
        <td v-if="originalSkill.skillGroupId">{{ originalSkill.skillGroupId }}</td>
        <td v-else>-</td>
      </tr>
      <tr>
        <td>{{__('entity.common.description')}}:</td>
        <td>{{ originalSkill.description }}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.created_at')}}:</td>
        <td>{{ originalSkill.createdAt }}</td>
      </tr>
      <tr>
        <td>{{__('entity.common.updated_at')}}:</td>
        <td>{{ originalSkill.updatedAt }}</td>
      </tr>
    </table>
    <p v-else>{{__('component.player_progression.edit_skill.original_skill.save_first')}}</p>
  </div>
</template>

<script>
  import { localizationMixin } from '../../../mixins/localization';

  export default {
    mixins: [localizationMixin],
    props: ['originalSkill']
  }
</script>