<template>
  <div class="edit-skill-levels">
    <h2>{{__('common.skill_levels')}}</h2>
    <progress-linear v-if="loading" :indeterminate="true"/>
    <div class="grid-table" style="grid-template-columns: 1fr 1fr 1fr 1fr">
      <template v-for="(skillLevel, index) in skillLevels">
        <div class="grid-table__cell">
          <text-field
              v-model="skillLevel.stage"
              :label="__('entity.player_progression.skill_level.stage')"
              :type="'number'"
              min="1" max="5"
              :hide-details="true"
              :prepend-icon="getPrependIcon(skillLevel)"
              @input="registerChange(skillLevel)"
          />
        </div>
        <div class="grid-table__cell">
          <text-field
              v-model="skillLevel.requiredTalentPoints"
              :label="__('entity.player_progression.skill_level.required_points')"
              :type="'number'"
              min="1"
              :hide-details="true"
              :prepend-icon="getPrependIcon(skillLevel)"
              @input="registerChange(skillLevel)"
          />
        </div>
        <div class="grid-table__cell">
          <edit-required-skill-level
              ref="editRequiredSkillLevel"
              :key="'editRequiredSkillLevel' + index"
              :changed="isChanged(skillLevel)"
              :parent="skillLevel"
              :skill-level-id="skillLevel.requiredSkillLevelId"
              @skillLevelSelected="handleSkillLevelSelected"
          />
        </div>
        <div class="grid-table__cell grid-table__cell--clickable" @click="toggleDeleted(skillLevel)">
          <img class="grid-table__cell-icon grid-table__cell-icon--small" src="https://cdn.hyperion-corporation.de/ui/svgs/bin.svg" alt="x"/>
        </div>
      </template>
    </div>

    <div class="layout layout--wrap" v-if="skillId != null">
      <div class="flex xs12">
        <div class="layout">
          <div class="flex xs6 mt">
            <div class="btn btn--success" :loading="loading" :disabled="!hasChanges()" @click="saveSkillLevels"
                 v-slashes>{{ __('common.save_changes') }}
            </div>
          </div>
          <div class="flex xs6 mt">
            <div class="btn btn--info" :loadding="loading" @click="addSkillLevel" v-slashes>
              {{__('component.player_progression.edit_skill.edit_skill_levels.add_skill_level')}}
            </div>
          </div>
          <div class="flex xs6 mt">
            <div class="btn btn--info" :loadding="loading" @click="autofill" v-slashes>
              autofill
            </div>
          </div>
        </div>
      </div>
    </div>
    <p v-else>{{__('component.player_progression.edit_skill.edit_skill_levels.save_first')}}</p>
  </div>
</template>

<script>
  import apiSecured from '../../../api/secured';
  import { notificationsMixin } from '../../../mixins/notifications';
  import { localizationMixin } from '../../../mixins/localization';
  import EditRequiredSkillLevel from '../Common/EditRequiredSkillLevel';
  import Swal from "sweetalert2";

  export default {
    components: { EditRequiredSkillLevel },
    mixins: [notificationsMixin, localizationMixin],
    data: () => ({
      loading: false,
      skillLevels: [],
      inProgress: 0,
      rerenderKey: 'no-set',
      talentPointsPresets: [
        [600,3000,15000,75000,375000],
        [1200,6000,30000,150000,750000],
        [1800,9000,45000,225000,1125000],
        [2400,12000,60000,300000,1500000],
        [3000,15000,75000,375000,1875000],
        [3600,18000,90000,450000,2250000],
        [4200,21000,105000,525000,2625000],
        [4800,24000,120000,600000,3000000],
        [5400,27000,135000,675000,3375000],
        [6000,30000,150000,750000,3750000],
        [6600,33000,165000,825000,4125000],
        [7200,36000,180000,900000,4500000],
      ]
    }),
    props: ['skillId'],
    watch: {
      skillId() {
        this.refresh();
      }
    },
    created() {
      this.refresh();
    },
    methods: {
      autofill() {
        if(this.skillLevels.length > 0) {
          Swal.fire({
            title: 'Forbidden',
            text: 'There are already skill levels',
            icon: 'error',
          });
          return;
        }
        Swal.fire({
          title: 'Select a preset',
          type: '',
          input: 'radio',
          inputOptions: this.talentPointsPresets.map(preset => `${preset[0]} - ${preset[preset.length - 1]}`),
          showCancelButton: true,
          confirmButtonText: this.__('Apply')
        }).then((result) => {
          if(result.isConfirmed) {
            const preset = this.talentPointsPresets[result.value];
            preset.forEach((points, index) => {
              this.addSkillLevel(index + 1, points);
            })
          }
        });
      },
      handleSkillLevelSelected({ skillLevelId, parent }) {
        const index = this.skillLevels.findIndex(skillLevel => {
          return parent.id === skillLevel.id
        });
        if (index > -1) {
          this.skillLevels[index].requiredSkillLevelId = skillLevelId;
          this.registerChange(this.skillLevels[index]);
          this.$forceUpdate();
        }
      },
      hasChanges() {
        return this.skillLevels.filter((skillLevel) => {
          return this.isNew(skillLevel) || this.isChanged(skillLevel) || this.isDeleted(skillLevel);
        }).length > 0;
      },
      refresh() {
        this.loading = true;
        this.skillLevels = [];
        if (this.skillId === null) {
          this.loading = false;
          return;
        }
        apiSecured.get('/ingame/player-progression/skilllevel/by-skill/'+this.skillId, {
          params: {
            orderBy: 'stage'
          }
        }).then((res) => {
          this.skillLevels = res.data;
          this.loading = false;
          this.showInfoNotification('Skill-Levels loaded')
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
      registerChange(skillLevel) {
        if (!this.isNew(skillLevel)) {
          skillLevel.changed = true;
          // this.forceRerender();
        }
      },
      isNew(skillLevel) {
        return skillLevel.hasOwnProperty('new') && skillLevel.new;
      },
      isChanged(skillLevel) {
        return skillLevel.hasOwnProperty('changed') && skillLevel.changed;
      },
      isDeleted(skillLevel) {
        return skillLevel.hasOwnProperty('deleted') && skillLevel.deleted;
      },
      addSkillLevel(stage = null, requiredTalentPoints = null) {
        this.skillLevels.push(
          {
            id: null,
            skillId: this.skillId,
            stage: stage ? stage : 1,
            requiredTalentPoints: requiredTalentPoints ? requiredTalentPoints : 0,
            requiredSkillLevelId: null,
            new: true
          }
        )
      },
      toggleDeleted(skillLevel) {
        if (this.isNew(skillLevel)) {
          this.skillLevels = this.skillLevels.filter((fSkillLevel) => fSkillLevel !== skillLevel);
          this.$forceUpdate();
        } else {
          skillLevel.deleted = !this.isDeleted(skillLevel);
          this.$forceUpdate();
        }
      },
      getPrependIcon(skillLevel) {
        if (this.isDeleted(skillLevel)) {
          return 'bin'
        } else if (this.isChanged(skillLevel)) {
          return 'pencil'
        } else if (this.isNew(skillLevel)) {
          return 'asterisk'
        }
        return null;
      },
      saveSkillLevels() {
        this.loading = true;
        let method = null;
        let payload = null;
        let event = null;
        this.skillLevels.forEach((skillLevel) => {
          let endpoint = null;
          let event = null;
          if (this.isNew(skillLevel)) {
            endpoint = '/ingame/player-progression/skilllevel';
            event = 'skillLevelCreated';
            method = apiSecured.post;
            payload = {
              stage: skillLevel.stage,
              skillId: skillLevel.skillId,
              requiredTalentPoints: skillLevel.requiredTalentPoints,
              requiredSkillLevelId: skillLevel.requiredSkillLevelId
            };
          } else if (this.isChanged(skillLevel)) {
            endpoint = '/ingame/player-progression/skilllevel/' + skillLevel.id;
            event = 'skillLevelUpdated';
            method = apiSecured.patch;
            payload = [
              {
                op: 'replace',
                path: '/stage',
                value: skillLevel.stage
              },
              {
                op: 'replace',
                path: '/requiredTalentPoints',
                value: skillLevel.requiredTalentPoints
              },
              {
                op: 'replace',
                path: '/requiredSkillLevelId',
                value: skillLevel.requiredSkillLevelId
              },
            ];
          } else if (this.isDeleted(skillLevel)) {
            endpoint = '/ingame/player-progression/skilllevel/' + skillLevel.id;
            event = 'skillLevelDeleted';
            method = apiSecured.delete;
            payload = {};
          } else {
            return;
          }
          this.inProgress++;
          method(endpoint, payload).then((res) => {
            this.showSuccessNotification('common.saved');
            this.$emit(event);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
              this.refresh();
              this.$emit('updated')
            }
          }).catch((error) => {
            this.loading = false;
            this.showErrorNotifications(error);
            this.inProgress--;
            if (this.inProgress <= 0) {
              this.loading = false;
              this.inProgress = 0;
            }
          });
        })
      }
    }
  }
</script>