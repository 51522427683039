<template>
  <my-aside :show="shown" @close="closeAside()">
    <div class="skill-tree__selected-skill-level" v-if="skill">
      <div class="padding-box">
        <h2 class="text--teal">{{ skill.name }}</h2>
        <p>{{ skill.description }}</p>
        <single-select
            class="mt"
            :items="skill.skillLevels.map(skillLevel => { return { value: skillLevel.stage, title: skillLevel.stage } })"
            v-model="skillStage"
        />
        <table class="editable-table" v-if="currentSkillLevel">
          <tr class="editable-table__row"
              v-for="player in currentSkillLevel.players">
            <td class="editable-table__icon">
              <img
                  :src="player.avatar ? player.avatar : 'https://cdn.hyperion-corporation.de/ui/placeholder_avatar.png'"/>
            </td>
            <td>{{ player.name }}</td>
          </tr>
        </table>
      </div>
    </div>
  </my-aside>
</template>

<script>
export default {
  name: "SkillDetails",
  data: () => ({
    skill: null,
    skillStage: 1,
    shown: false,
  }),
  computed: {
    currentSkillLevel() {
      if(this.skillStage) {
        return this.skill.skillLevels.find(skillLevel => skillLevel.stage === this.skillStage);
      }
      return [];
    }
  },
  methods: {
    showAside(skill, stage) {
      this.skill = skill;
      this.skillStage = stage;
      this.shown = true;
    },
    closeAside() {
      this.skillStage = 1;
      this.skill = null;
      this.shown = false;
    }
  }
}
</script>

<style scoped>

</style>