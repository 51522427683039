<template>
  <div class="player-registration">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{__('playerRegistration')}}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <div class="tile">
                <div class="bodytext bodytext--larger">
                  <img class="full-width" src="https://cdn.hyperion-corporation.de/ui/surrogate_stations.jpg" alt="surrogate stations"/>
                  <div v-html="__('playerRegistrationExplanation')"></div>
                  <div class="token">{{token}}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
  import { localizationMixin } from '../../mixins/localization';
  import apiSecured from "../../api/secured";
  import { notificationsMixin } from "../../mixins/notifications";

  export default {
    mixins: [localizationMixin, notificationsMixin],
    data: () => ({
      token: '',
      show: false,
      loading: false
    }),
    methods: {
      showDialog() {
        this.show = true;
        this.$emit('shown');
        this.refresh();
      },
      close() {
        this.show = false;
        this.$emit('closed');
      },
      refresh() {
        apiSecured.get('/identity/myself/player-registration-token').then((res) => {
          this.token = res.data.token;
          this.loading = false;
          this.showInfoNotification(this.__('common.data_loaded'));
        }).catch((error) => {
          this.token = '';
          this.loading = false;
          this.showErrorNotifications(error)
        });
      },
    }
  }
</script>