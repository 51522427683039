<template>
  <div class="edit-required-skill-level">
    <div class="select-reference">
      <progress-linear v-if="loading" :indeterminate="true"/>
      <div class="select-reference__append-icon"><icon v-if="changed" :icon="'pencil'"/></div>
      <div class="select-reference__title" @click="showSkillLevelBrowser">
        <template v-if="skill">{{ skill.name }}</template>
        <template v-else>No skill level selected</template>
      </div>
      <div class="select-reference__title select-reference__title--fixed-width" @click="showSkillLevelBrowser">
        <template v-if="skillLevel">{{ skillLevel.stage }}</template>
        <template v-else>-</template>
      </div>
      <div class="select-reference__title select-reference__title--remove" v-if="skillLevel" @click="handleSkillLevelSelected({ skillLevel: {id: null} })">
        <icon :icon="'bin'"/>
      </div>
    </div>
    <skill-level-browser ref="skillLevelBrowser" @skillLevelSelected="handleSkillLevelSelected"/>
  </div>
</template>

<script>
  import SkillLevelBrowser from './SkillLevelBrowser';
  import apiSecured from "../../../api/secured";
  import { notificationsMixin } from "../../../mixins/notifications";
  import { localizationMixin } from "../../../mixins/localization";

  export default {
    components: { SkillLevelBrowser },
    mixins: [notificationsMixin, localizationMixin],
    props: ['changed', 'skillLevelId', 'parent'],
    data: () => ({
      skillLevel: null,
      skill: null,
      loading: false
    }),
    created() {
      this.refresh();
    },
    watch: {
      skillLevelId() {
        this.skill = null;
        this.skillLevel = null;
        this.refresh();
      }
    },
    methods: {
      showSkillLevelBrowser() {
        this.$refs.skillLevelBrowser.showDialog();
      },
      handleSkillLevelSelected({skillLevel}) {
        this.$emit('skillLevelSelected', {skillLevelId: skillLevel.id, parent: this.parent });
      },
      async refresh() {
        if(this.skillLevelId === null)
          return;
        this.loading = true;
        this.skillLevel = null;
        this.skill = null;
        await apiSecured.get('/ingame/player-progression/skilllevel/' + this.skillLevelId).then(async (res) => {
          this.skillLevel = res.data;
          this.loading = false;
          await this.refreshSkill();
          this.showInfoNotification(this.__('common.skilllevel_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
      async refreshSkill() {
        if(this.skillLevel === null)
          return;

        this.loading = true;
        await apiSecured.get('/ingame/player-progression/skill/' + this.skillLevel.skillId).then((res) => {
          this.skill = res.data;
          this.loading = false;
          this.showInfoNotification(this.__('common.skill_loaded'));
        }).catch((error) => {
          this.loading = false;
          this.showErrorNotifications(error);
        });
      },
    }
  }
</script>