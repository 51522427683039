<template>
  <div class="organization-legate-view">
    <my-dialog :show="show" @close="close" max-width="800px">
      <div class="card">
        <div class="card__header">
          <div class="card__title">{{ __('manageOrganizations') }}</div>
          <div class="card__button" @click="close" v-slashes>
            <img src="https://cdn.hyperion-corporation.de/ui/svgs/cross.svg" alt="x"/>
          </div>
        </div>
        <div class="card__content">
          <progress-linear v-if="loading"/>
          <div class="layout layout--wrap">
            <div class="flex xs12">
              <div class="tile bodytext bodytext--larger" style="height: auto"
                   v-html="__('organizationViewExplanation')"></div>
              <table class="editable-table">
                <template v-for="player in players">
                  <tr v-for="(membership, index) in player.memberships"
                      :key="`membership_${player.id}_${membership.id}`">
                    <td>
                      <img v-if="player.avatar && player.avatar.substr(0,4).toLocaleLowerCase() === 'http'"
                           :src="player.avatar" alt="-"
                      />
                      <div v-else v-html="player.avatar"></div>
                    </td>
                    <td>{{ player.name }}</td>
                    <td v-if="membership.organization">
                      <img
                          v-if="membership.organization.logo && membership.organization.logo.substr(0,4).toLocaleLowerCase() === 'http'"
                          :src="membership.organization.logo" alt="-"
                      />
                      <div v-else v-html="membership.organization.logo"></div>
                    </td>
                    <td v-if="membership.organization">{{ membership.organization.name }}</td>
                    <td>{{ membershipTypes[membership.type] }}</td>
                    <td>
                      <my-switch
                          v-model="membership.organization.isFaction"
                          :label="__('enableOrganizationView')"
                          :append-icon="changedOrganizationIds.includes(membership.organization.id) ? 'pencil' : null"
                          @input="registerChange(membership.organization.id, membership.organization.isFaction)"
                      />
                    </td>
                  </tr>
                </template>
              </table>
            </div>
            <div class="flex xs12">
              <div class="layout">
                <div class="flex xs6 mt">
                  <div class="btn btn--success" :loading="loading"
                       :disabled="changedOrganizationIds.length === 0"
                       @click="updateOrganizations" v-slashes>{{ __('common.save_changes') }}
                  </div>
                </div>
                <div class="flex xs6 mt">
                  <div class="btn btn--primary" @click="close" v-slashes>{{ __('common.close') }}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </my-dialog>
  </div>
</template>

<script>
import { localizationMixin } from '../../mixins/localization';
import apiSecured from "../../api/secured";
import { notificationsMixin } from "../../mixins/notifications";

export default {
  mixins: [localizationMixin, notificationsMixin],
  data: () => ({
    legations: [],
    players: [],
    show: false,
    loading: false,
    membershipTypes: [
      'Member',
      'Legate',
      'SuperLegate'
    ],
    organizations: [],
    changedOrganizationIds: [],
  }),
  methods: {
    registerChange(organizationId, isFaction) {
      this.organizations.map(organization => {
        if (organization.id === organizationId) {
          organization.isFaction = isFaction;
          if (!this.changedOrganizationIds.includes(organizationId)) {
            this.changedOrganizationIds.push(organizationId);
          }
        }
      })
    },
    showDialog() {
      this.show = true;
      this.$emit('shown');
      this.refresh();
    },
    close() {
      this.show = false;
      this.$emit('closed');
    },
    async refresh() {
      this.loading = true;
      this.players = [];
      this.legations = [];
      this.organizations = [];
      this.changedOrganizationIds = [];
      await this.refreshPlayers();
      this.loading = false;
    },
    async refreshPlayers() {
      await apiSecured.get('/ingame/civilization/player/by-current-user').then(async (res) => {
        this.players = res.data;
        let memberships = [];
        this.players.forEach(player => {
          memberships.push(...player.organizationMemberships.filter(membership => membership.type > 0));
        });
        const orgIds = memberships.map(membership => membership.organizationId);
        if (orgIds.length > 0) {
          await apiSecured.get(`/ingame/civilization/organization/(${orgIds.join(',')})`).then((res) => {
            this.organizations = res.data;
            memberships = memberships.map(membership => {
              membership.organization = res.data.find(organization => organization.id === membership.organizationId);
              return membership;
            });
          }).catch((error) => {
            this.showErrorNotifications(error);
          });
        }
        this.players = this.players.map(player => {
          player.memberships = memberships.filter(membership => membership.playerId === player.id);
          return player;
        })
      }).catch((error) => {
        this.showErrorNotifications(error);
      });
    },
    async updateOrganizations() {
      this.loading = true;
      const toUpdate = this.organizations.filter(organization => this.changedOrganizationIds.includes(organization.id));
      const payload = toUpdate.map(organization => {
        return { factionId: organization.id, isFaction: organization.isFaction };
      });
      await apiSecured.patch('/ingame/civilization/faction',payload).then((res) => {
        this.showSuccessNotification(this.__('common.saved'));
        this.loading = false;
        this.refresh();
      }).catch((error) => {
        this.showErrorNotifications(error);
        this.loading = false;
      });
    }
  }
}
</script>